<template>
  <div>
    <PageHeader
      :key="globalLanguage"
      :items="breadcrumbItems"
      :optionalItems="optionalItems"
    >
      <template #filters>
        <div class="search-filters">
          <search-filter :isFilterSearch="false">
            <div class="form-group">
              <label class="input-label form-label"
                >{{ $t("Customer") }}
              </label>
              <MultiSelectInput
                v-model="form.company"
                :options="customers"
                label="companyName"
                trackBy="id"
                moduleName="customers"
                :multiple="false"
                ><template #option="{ props }">
                  <div>
                    <p>
                      {{ props.option.companyNumber }} -
                      {{ props.option.companyName }}
                    </p>
                  </div>
                </template>
              </MultiSelectInput>
            </div>
          </search-filter>
          <div class="page-header-search">
            <input
              autocomplete="off"
              type="text"
              name="search"
              :placeholder="$t('Search…')"
              v-model="form.search"
            />
            <button class="" type="button" @click="reset">
              {{ $t("Reset") }}
            </button>
          </div>
        </div>
      </template>
    </PageHeader>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="documentServices.data"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->

            <span
              class="d-flex align-items-center justify-content-center gap-2"
              v-if="props.column.field === 'action'"
            >
              <div
                v-if="$can(`${$route.meta.permission}.list`)"
                class="cursor-pointer"
                @click="
                  $router.push(`/document-templates/${props.row.id}/show`)
                "
              >
                <feather-icon size="16" icon="EyeIcon" />
              </div>
              <div
                v-if="$can(`${$route.meta.permission}.edit`)"
                class="cursor-pointer"
                @click="
                  $router.push(`/document-templates/${props.row.id}/edit`)
                "
              >
                <feather-icon size="16" icon="Edit2Icon" />
              </div>
              <div
                v-if="$can(`${$route.meta.permission}.delete`)"
                class="cursor-pointer"
                @click="destroy(props.row.id)"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </div>
            </span>
            <span v-else-if="props.column.field === 'documentGenerated'">
              {{ props.row.generated_document_count }}
            </span>
            <span v-else-if="props.column.field === 'company'">
              {{ companyNames[props.row.company_id] }}
            </span>
            <span v-else-if="props.column.field === 'creationDate'">
              {{
                $dateFormatter(
                  new Date((props.row.creation_date || 0) * 1000)
                    .toISOString()
                    .slice(0, 10),
                  $i18n.locale
                )
              }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-center flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <div class="row">
        <div
          class="col-md-4"
          v-for="row in documentServices.data"
          :key="row.id"
        >
          <div class="card mb-1">
            <div class="card-header">
              <h5 class="card-title">{{ row.subject }}</h5>
              <div
                class="card-actions d-flex gap-2"
                v-if="
                  $can(`${$route.meta.permission}.edit`) ||
                  $can(`${$route.meta.permission}.delete`) ||
                  $can(`${$route.meta.permission}.list`)
                "
              >
                <button
                  v-if="$can(`${$route.meta.permission}.list`)"
                  @click="$router.push(`/document-templates/${row.id}/show`)"
                  class=""
                >
                  <feather-icon icon="EyeIcon" size="18" />
                </button>
                <button
                  v-if="$can(`${$route.meta.permission}.edit`)"
                  @click="$router.push(`/document-templates/${row.id}/edit`)"
                  class=""
                >
                  <feather-icon icon="Edit2Icon" size="18" />
                </button>
                <button
                  v-if="$can(`${$route.meta.permission}.delete`)"
                  @click="destroy(row.id)"
                  class=""
                >
                  <feather-icon icon="TrashIcon" size="18" />
                </button>
              </div>
            </div>
            <div class="card-body">
              <p class="card-text">
                <strong>{{ $t("ID") }}</strong>
                {{ row.id }}
              </p>
              <p class="card-text">
                <strong>{{ $t("Name") }}</strong>
                {{ row.name }}
              </p>
              <p class="card-text">
                <strong>{{ $t("Document Generated") }}</strong>
                {{ row.generated_document_count }}
              </p>
              <p class="card-text">
                <strong>{{ $t("Company") }}</strong>
                {{ companyNames[row.companyId] }}
              </p>
              <p class="card-text">
                <strong>{{ $t("Creation Date") }}</strong>
                {{
                  $dateFormatter(
                    new Date((row.creation_date || 0) * 1000)
                      .toISOString()
                      .slice(0, 10),
                    $i18n.locale
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--====================================-->
  </div>
</template>

<script>
import SearchFilter from "@/components/SearchFilter.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import { mapGetters } from "vuex";
import { debounce } from "@/utils/debounce";

export default {
  components: {
    PageHeader,
    SearchFilter,
    MultiSelectInput,
  },
  computed: {
    ...mapGetters("documentService", ["documentServices", "count"]),
    ...mapGetters("customers", ["customers"]),
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Document Template"),
          icon: "PlusIcon",
          path: "/document-templates/create",
          permission: `${this.$route.meta.permission}.create`,
        },
      };
    },
    breadcrumbItems() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Document Templates"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("ID"),
          field: "id",
          sortable: false,
        },
        {
          label: this.$t("Name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("Document Generated"),
          field: "documentGenerated",
          sortable: false,
        },
        {
          label: this.$t("Company"),
          field: "company",
          sortable: false,
        },
        {
          label: this.$t("Creation Date"),
          field: "creationDate",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 25,
      sortBy: "createdAt",
      sortOrder: "desc",
      companyNames: {},
      currentPage: 1,
      start: 0,
      perPage: 25,
      form: {
        search: "",
        companyId: "",
      },
      companies: [],
      window,
    };
  },
  watch: {
    documentServices: {
      handler: async function (val) {
        for (let i = 0; i < val?.data?.length ?? 0; i++) {
          if (this.companyNames[val.data[i].company_id]) {
            continue;
          }
          let company = this.customers.find(
            (company) => company.id == val.data[i].company_id
          );
          if (company) {
            this.companyNames[val.data[i].company_id] = company.companyName;
          } else if (val.data[i].company_id) {
            try {
              const response = await this.$store.dispatch(
                "customers/show",
                val.data[i].company_id
              );
              this.companyNames[val.data[i].company_id] =
                response?.data?.modelData?.companyName ?? "";
            } catch (e) {
              console.log(e);
            }
          }
          console.log("companyNames", this.companyNames);
        }
      },
      deep: true,
    },
    "form.companyId"(...val) {
      this.debouncedFetch(...val);
    },
    "form.search"(...val) {
      this.debouncedFetch(...val);
    },
  },
  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.$store.dispatch("documentService/list", {
          limit_start: this.start,
          limit_count: this.perPage,
          ...(this.form?.companyId?.id && {
            company_id: this.form?.companyId?.id,
          }),
          search_string: this.form?.search,
        });
      } catch (e) {
        console.log("error is ", e);
      } finally {
      }
    }, 300);
  },
  async mounted() {
    await this.loadItems(this.start, this.perPage);
    this.totalRecords = this.count;
    if (!this.customers?.length)
      this.$store.dispatch("customers/list", {
        page: 1,
        perPage: 25,
      });
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },
    onSortChange(params) {
      this.sortOrder = params[0].type;
      if (params[0].type == "none") this.sortOrder = "asc";

      this.sortBy = params[0].field;
      this.loadItems();
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    async loadItems(start, end) {
      try {
        this.$store.commit("showLoader", true);
        await this.$nextTick();
        await this.$store.dispatch("documentService/list", {
          limit_start: start,
          limit_count: end,
          company_id: this.form.companyId?.id,
          search_string: this.form.search,
        });
      } catch (error) {
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        await this.$store
          .dispatch("documentService/destroy", { id: id })
          .finally(() => {
            this.loadItems();
          });
      }
    },
    reset() {
      this.form = this.mapValues(this.form, () => null);
    },
  },
};
</script>
